import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import {
  ShipperRequestModel,
  ShipperRequestSchema
} from "../../../app/data/company-settings/models";
import XGSPopup from "../../../ui-components/xgs-popup/xgsPopup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import CompanySettingsShippersState from "../../../slices/company-settings/CompanySettingsShippersState";
import {
  companySettingsShippersSelector,
  resetErrors,
  requestShipper
} from "../../../slices/company-settings/companySettingsShippersSlice";
import { usaStates } from "../../../app/data/common/usaStates";

export interface AddShipperProps {
  show: boolean;
  onRequestSent: () => void;
  onClose: () => void;
}

let initialValues: ShipperRequestModel = {
  name: "",
  address: {
    address1: "",
    city: "",
    state: "",
    postalCode: ""
  }
};

const AddShipper: React.FC<AddShipperProps> = (props) => {
  const shippersState: CompanySettingsShippersState = useSelector(companySettingsShippersSelector);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();
  const dispatch = useDispatch();

  const onSubmitRequest = (data: ShipperRequestModel) => {
    dispatch(requestShipper(data, () => {
      toast.info("Request sent! We will add shipper or contact you as soon as possible.");
      props.onRequestSent();
      props.onClose();
    }));
  };

  const onClose = () => {
    setStateValue(null);
    props.onClose();
  };

  useEffect(() => {
    setStateValue(null);
    dispatch(resetErrors());

    return () => {
      dispatch(resetErrors());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!shippersState.requestFailed) return;
    toast.error(shippersState.requestError);
  }, [shippersState.requestFailed, shippersState.requestError]);

  return (
    <XGSPopup
      title=""
      opened={props.show}
      onClose={onClose}
      className="xgs-modal"
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div>
        <div className="xgs-modal__header">Request Add Shipper</div>
        <div className="xgs-modal__content">
          <Formik
            onSubmit={onSubmitRequest}
            initialValues={initialValues}
            validationSchema={ShipperRequestSchema}
          >
            {(props: FormikProps<ShipperRequestModel>) => (
              <Form>
                <XGSFormInput
                  type="text"
                  name="name"
                  label="Company:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-form__field xgs-form__field--no-min-height"
                />
                <AddressLookup
                  onValueChange={(val) => {
                    props.setFieldValue("address.address1", val.address);
                    props.setFieldValue("address.city", val.city);
                    props.setFieldValue("address.state", val.state);
                    const stateOption = usaStates.find(state => state.value === val.state);
                    stateOption && setStateValue(stateOption);
                    props.setFieldValue("address.postalCode", val.zip);
                    setTimeout(() => {
                      props.setFieldTouched("address.address1", true, true);
                    }, 100);
                  }}
                  onBlur={() => {
                    props.setFieldTouched("address.address1", true, true);
                  }}
                />
                <div className="xgs-form__address-block">
                  <XGSFormInput
                    type="text"
                    name="address.address1"
                    label="Address:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className=""
                  />
                  <XGSFormInput
                    type="text"
                    name="address.city"
                    label="City:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className=""
                  />
                  <div className="">
                    <XGSFormSelect
                      isSearchable={false}
                      label="State:"
                      labelMode={LabelModes.column}
                      name="address.state"
                      onValueChange={(v: any) => {
                        props.setFieldValue("address.state", v?.value);
                        if (v?.value) {
                          const stateOption = usaStates.find(state => state.value === v.value);
                          stateOption && setStateValue(stateOption);
                        }
                      }}
                      options={usaStates}
                      required={true}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      value={stateValue}
                      className=""
                    />
                    <XGSFormInput
                      type="text"
                      name="address.postalCode"
                      label="Zip:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                      className=""
                    />
                  </div>
                </div>
                <div className="xgs-modal__buttons">
                  <Button
                    theme={ButtonThemes.blue}
                    type="submit"
                    disabled={!props.isValid || !props.dirty}
                    spinner={shippersState.requestStarted && shippersState.requestCreator === "SEND_REQUEST"}
                    className="xgs-modal__button"
                  >
                    Send&nbsp;request
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </XGSPopup>
  );
};

export default AddShipper;
